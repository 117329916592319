import { Component } from "react";
import NoAuth from "../NoAuth";

import { getDropOrder, saveDropOrder } from '../southbound/store/southboundActions';
import DropOrderList from "./DropOrderList";
import SuccessModal from "./SuccessModal";


function Spinner() {
  return (
    <div className="flex items-center justify-center ">
        <div className="w-4 h-4 border-b-2 border-gray-50 rounded-full animate-spin mr-2"></div>
    </div>
  )
}



class SouthboundDriver extends Component {

  state = {
    isAuth: false,
    isSuccess: false,
    isSavingForm: false,
    formData: { shipments: [] },
    drivers: [],
    trucks: [],
    trailers: []
  }
  
  componentDidMount() {

    const token = new URLSearchParams(window.location.search).get('token');
    const loadId = new URLSearchParams(window.location.search).get('loadId');
    const formId = new URLSearchParams(window.location.search).get('formId');
    
    this.loadForm(formId, loadId, token);
    this.setState({ isAuth: token !== null, loadId, token, formId });

  }

  
  
  render() {
      
    if (!this.state.isAuth) {
      return <NoAuth />
    }
    
    return (
      <div>
        <main className="relative py-8">
          <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
            {
              this.state.isSuccess ? this.renderSuccess() : this.renderForm()
            }
          </div>
        </main>
      </div>
    )
  }




  // MARK: Render Sub Components

  renderSuccess = () => {
    return (
      <div className="w-full h-screen" >
        <div className="bg-white rounded-lg shadow overflow-hidden py-24">
          <SuccessModal />
        </div>
      </div>
    )
  }

  renderForm = () => {
    return (
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <form className="divide-y divide-gray-200 lg:col-span-12" onSubmit={this.handleSubmit} >
          <div className="py-6 px-4 sm:p-6 lg:pb-8">
            {this.renderList(this.state.formData.shipments)}                    
          </div>
          <div className="pt-6 divide-y divide-gray-200">
            
            <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
              <button
                type="submit"
                className="ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                {
                  this.state.isSavingForm ? <Spinner /> : 'Save'
                }
              </button>
            </div>
          </div>
        </form>
          
        </div>
      </div>
    )
  }

  renderList = shipments => {
    return <DropOrderList shipments={shipments} onChange={this.onChange} />
  }

  onChange = (event, shipment) => {
    
    const id = event.target.id;
    const value = event.target.value;

    var newShipment = {
      ...shipment
    }
    newShipment['drop_order'] = parseInt(value);
    newShipment['dropOrder'] = parseInt(value);
    
    var formData = {
      ...this.state.formData
    };
    

    var updatedShipments = this.state.formData.shipments.map(ship => {
      if (ship.shipment_id === id) {
        return newShipment
      }
      return ship
    });
    
    formData.shipments = updatedShipments;
    this.setState({ formData });

  }


  // MARK: Helpers

  loadForm = async (formId, loadId, token) => {

    const data = await getDropOrder(formId, loadId, token);
    
    var load = {...data};
    load.data.shipments = load.data.shipments.map((shipment, idx) => {
      var newShip = {
        ...shipment
      }

      newShip.shipment_id = `${shipment.shipment_id}`

      if (newShip.dropOrder === undefined) {
        newShip.dropOrder = idx
      }

      if (newShip.drop_order === undefined) {
        newShip.drop_order = idx
      }

      return newShip
    })
    
    this.setState({ object: load, formData: load.data, loadId });

  }

  isValid = () => {
    const { formData } = this.state;
    // const dropOrders = formData.shipments.map(shipment => shipment.dropOrder)
    // const isDuplicate = new Set(dropOrders).size !== dropOrders.length;
    
    var duplicates = {};
    
    formData.shipments.forEach(shipment => {
      if (duplicates[shipment.drop_order]) {
        duplicates[shipment.drop_order].push(shipment.shipment_id)
      } else {
        duplicates[shipment.drop_order] = [shipment.shipment_id]
      }
    });

    for (const [key, value] of Object.entries(duplicates)) {
      
      if (value && value.length && value.length > 1) {
        let message = value.map(shipmentId => {
          return formData.shipments.filter(ship => ship.shipment_id === shipmentId)[0].consignee || 'no consignee'
        }).join(", ") + ' have the same drop order' 
        
        alert(message);

        return false
        
      }
    }

    return true;
  }

  handleSubmit = async (event) => {
    
    event.preventDefault();
    
    if (this.state.isSavingForm) {
      return;
    }

    if (this.isValid() === false) {
      return;
    }

    const { object, formData } = this.state;

    var load = {
      ...object,
    };
    
    load.data = {
      ...formData,
      is_drop_order_set: true
    };
    
    
    this.setState({ isSavingForm: true });
    
    const response = await saveDropOrder(this.state.formId, this.state.loadId, this.state.token, load);
    this.setState({ isSavingForm: false });
    
    if (response.status === 200) {
      this.setState({ isSuccess: true });
    } else  {
      alert("There was an error updating drop order");
      // add sentry log
    }

  }


}




export default SouthboundDriver;