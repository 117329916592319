import * as types from './types';
import { updateObject } from '../../../store/utils';

const initialState = {
  auth: {},
  error: {},
  redirect: {},
  isLoading: false
}


const authStart = (state, action) => {
  return updateObject(state, { error: null, isLoading: true });
}

const setAuthRedirect = (state, action) => {
  return updateObject(state, {
    redirect: {
      path: action.path,
      data: action.data
    }
  })
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    auth: action.auth,
    error: null,
    isLoading: false
  })
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false
  })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_START:
      return authStart(state, action);
    case types.AUTH_SUCCESS:
      return authSuccess(state, action);
    case types.AUTH_FAIL:
      return authFail(state, action);
    case types.SET_AUTH_REDIRECT:
      return setAuthRedirect(state, action);
    default:
      return state;
  }
}


export default reducer;