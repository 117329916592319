import { CheckIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const steps = [
  { name: 'Process Southbound Email', isComplete: true, isActive: false },
  { name: 'Create Load', isComplete: true, isActive: false },
  { name: 'Assign Driver', isComplete: false, isActive: true },
  { name: 'Driver Confirm Drop Order', isComplete: false, isActive: false },
  { name: 'Create Paps', isComplete: false, isActive: false },
  { name: 'Email Paps', isComplete: false, isActive: false },
  { name: 'Email Load Plan', isComplete: false, isActive: false },
  { name: 'Create Customs Entry', isComplete: false, isActive: false },
]

export default function AutomationSteps() {
  return (
    <nav className="space-y-1">
      {steps.map((item) => (
        <div
          key={item.name}
          href={item.href}
          className={classNames(
            item.isActive
              ? 'bg-blue-50 border-blue-500 text-blue-700'
              : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
            'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
          )}
          aria-current={item.isComplete ? 'page' : undefined}
        >
          <div className={`mr-2 flex items-center justify-center h-6 w-6 rounded-full bg-${item.isComplete || item.isActive ? 'blue' : 'gray'}-100`}>
            <CheckIcon className={`h-4 w-4 text-${item.isComplete || item.isActive ? 'blue' : 'gray'}-600`} aria-hidden="true" />
          </div>
          
          <span className="truncate">{item.name}</span>
        </div>
      ))}
    </nav>
  )
}