import { Component } from "react";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

import NoAuth from "../NoAuth";

import { getDrivers, getFleet, getLoad, saveForm } from './store/southboundActions';
import AutomationSteps from '../../components/AutomationSteps';
import Load from "./loads/Load";
import SuccessBanner from "./SuccessBanner";
import Spinner from "./Spinner";
import EmptyState from "./EmptyState";

import { getPaps, generatePap, getDuplicatePaps, isPapsValid } from "./paps";


class Southbound extends Component {

  state = {
    isAuth: false,
    isSaving: false,
    isSaveSuccess: false,
    isLoading: true,
    paps_key: "",
    loads: [],
    drivers: [],
    trucks: [],
    trailers: []
  }
  
  componentDidMount() {

    const token = new URLSearchParams(window.location.search).get('token');
    const loadId = new URLSearchParams(window.location.search).get('loadId');
    const formId = new URLSearchParams(window.location.search).get('formId');
    
    this.loadForm(loadId, token);
    this.setState({ isAuth: token !== null, loadId, token, formId });

  }

  
  
  render() {
      
    if (!this.state.isAuth) {
      return <NoAuth />
    }

    return (
      <div>
        <main className="relative py-8">
          <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="py-6 lg:col-span-3">
                  <AutomationSteps />
                </aside>
                {this.state.isLoading ? <EmptyState /> : this.renderLoads()}
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }

  renderEmptyState = () => {
    return <p>loading...</p>
  }


  // MARK: Render Sub Components
  renderLoads = () => {
    return (
      <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={this.handleSubmit} >
        
        {
          this.state.loads.map((load, idx) => <Load key={idx} idx={idx} load={load} drivers={this.state.drivers} trucks={this.state.trucks} trailers={this.state.trailers} updateLoad={this.udateLoad} deleteTrip={this.deleteTrip} />)
        }
        
        <div className="pt-6 divide-y divide-gray-200">
          <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
            {
              this.state.isSaveSuccess ? <SuccessBanner onDismiss={this.dismissBanner} /> : this.renderSaveFooter()
            }
          </div>
        </div>
      </form>
    )
  }

  renderSaveFooter = () => {
    return (
      <div >
        <button
          onClick={(e) => this.addPaps(e)}
          className="ml-5  border border-blue-700 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-blue-700 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
        >
          Add PAPS
        </button>
        <button
          onClick={(e) => this.addTrip(e)}
          className="ml-5  border border-blue-700 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-blue-700 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
        >
          Add Load
        </button>
        {this.renderSaveBtn()}
      </div>
    )
  }

  renderSaveBtn = () => {
    return (
      <button
        type="submit"
        className="ml-5 bg-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
      >
        {
          this.state.isSaving ? <Spinner /> : 'Save' 
        }
      </button>
    )
  }

  


  // MARK: Helpers

  deleteTrip = load => {
    const loads = this.state.loads.filter(item => item.entity_id === load.entity_id);
    this.setState({ loads });
  }

  

  addPaps = event => {
    event.preventDefault();
    var loads = this.state.loads || [];
    
    const date = moment().format("YYMMDD");
    
    const paps = getPaps(this.state.loads);
    var count = paps.count || 0;

    const papsKey = this.state.paps_key;

    const updates = loads = loads.map(load => {
      const shipments = load.data.shipments || [];
      const data = {
        ...load.data,
        shipments: shipments.map(shipment => {
          const ship = {...shipment}
          if (!ship.hasOwnProperty("paps") || shipment["paps"] === "") {
            const new_paps = generatePap(papsKey, date, paps, count);
            ship["paps"] = new_paps.paps;
            count = new_paps.count;
          }
          
          return ship
        })
      }
      return {
        ...load,
        data
      }
    });

    this.setState({ loads: updates });
  }

  addTrip = event => {
    event.preventDefault();
    const loads = this.state.loads || [];
    loads.push(this.createLoad());
    this.setState({ loads });
  }

  udateLoad = newLoad => {
    
    const loads = this.state.loads.map(load => {
      if (load.entity_id === newLoad.entity_id) {
        return newLoad
      }
      return load
    });
    
    this.setState({ loads });
  }

  dismissBanner = () => {
    this.setState({ isSaveSuccess: false })
  }

  createLoad = () => {
    return {
      is_new: true,
      entity_id: this.state.loads.length,
      data: {
        driver: {},
        truck: {},
        trailer: {},
        shipments: []
      }
    }
  }

  scrubInitialData = loads => {
    return loads.map(load => {
      const data = {
        ...load.data,
        shipments: load.data.shipments.map((shipment, idx) => {
          var newShip = {
            ...shipment
          }
          
          if (newShip.shipment_id === undefined || typeof newShip.drop_order === "object") {
            newShip.shipment_id = uuidv4();
          }
    
          if (newShip.dropOrder === undefined || typeof newShip.drop_order === "object") {
            newShip.dropOrder = idx
          }
          
          if (newShip.drop_order === undefined || typeof newShip.drop_order === "object") {
            newShip.drop_order = idx
          }
    
          return newShip
        })
      }
      return {
        ...load,
        data
      }
    })
    
  }

  loadForm = async (loadId, token) => {

    const result = await getLoad(loadId, token);
    const driverData = await getDrivers(loadId, token) || [];
    const fleet = await getFleet(loadId, token) || [];
    
    const drivers = driverData.filter(driver => {
      if (driver.data.tag_ids) {
        return driver.data.tag_ids.includes('fe803cf5-e179-42e5-9b6f-007f48271bd4')
      }
      return false
    }).map(driver => ({ ...driver, name: driver.data.username, value: driver.entity_id }));

    const trucks = fleet.filter(item => item.data.type === 'truck').map(truck => ({ ...truck, name: truck.data.name, value: truck.entity_id }))
    const trailers = fleet.filter(item => item.data.type === 'reefer').map(trailer => ({ ...trailer, name: trailer.data.name, value: trailer.entity_id }));
    
    var select = [{ value: '', name: 'Select' }]

    const loads = result.loads || [];
    const paps_key = result.paps_key || "";
    this.setState({ 
      isLoading: false, 
      loads: this.scrubInitialData(loads), 
      paps_key, 
      drivers: select.concat(drivers), 
      trucks: select.concat(trucks), 
      trailers: select.concat(trailers), 
      loadId 
    });

  }

  isValid = () => {

    var valid = false;

    // check duplicate paps
    const dupliactePaps = getDuplicatePaps(this.state.loads);
    const duplicates = Object.values(dupliactePaps);
    if (!isPapsValid(this.state.loads)) {
      throw new Error("Not all paps have been set");
    }

    for (var i = 0; i < duplicates.length; i++) {
      if (duplicates[i].length > 1) {
        throw new Error(`${duplicates[i]} have duplicate paps`)
      }
    }
    
    this.state.loads.forEach((load, index) => {
      try {
        valid = this.isLoadValid(load.data, index);
      } catch (error) {
        throw error
      }
      
    });

    return valid;
  }

  isLoadValid = (formData, index) => {

    if (formData.driver === undefined || formData.driver === null) {
      throw new Error("Select Driver")
    }

    if (formData.truck === undefined || formData.truck === null) {
      throw new Error("Select Truck")
    }

    if (formData.trailer === undefined || formData.trailer === null) {
      throw new Error("Select Trailer")
    }

    if (formData.temp === undefined || formData.temp === null || formData.temp === "") {
      throw new Error("Enter Temperature")
    }

    if (formData.date === undefined || formData.date === null || formData.date === "") {
      throw new Error("Enter Date")
    }

    if (formData.time === undefined || formData.time === null || formData.time === "") {
      throw new Error("Enter Pick Up Time")
    }

    if (formData.shipments.map(shipment => shipment.cases || 0).reduce((a,b) => parseInt(a)+parseInt(b), 0) > 650) {
      throw new Error(`Load ${index + 1} has too many cases`)
    }
    
    return true
  }




  handleSubmit = async (event) => {
    event.preventDefault();
    
    if (this.state.isSaving) {
      return
    }

    try {
      this.isValid()
      const updatedLoads = this.state.loads.map(load => {
        if (load.is_new) {
          return {
            ...load.data,
            driver_name: load.data.driver.data.first_name || '',
            driver_samsara_id: load.data.driver.data.samsara_id || ''
          }
        }
        return {
          ...load,
          data: {
            ...load.data,
            driver_name: load.data.driver.data.first_name || '',
            driver_samsara_id: load.data.driver.data.samsara_id || ''
          }
        }
      });
      
      await this.setState({ isSaving: true });
      const response = await saveForm(this.state.loadId, this.state.token, updatedLoads);
      await this.setState({ isSaving: false });

      if (response.status === 200) {
        this.setState({ isSaveSuccess: true });
      } else {
        alert("Error saving stuff")
      }
      
    } catch (error) {
      await this.setState({ isSaving: false });
      alert(error.message);
    }
    

  }

  saveTemplateAsFile = (dataObjToWrite) => {
    const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
    const link = document.createElement("a");

    link.download = 'loadResponse.json';
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
};

}




export default Southbound;