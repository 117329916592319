import { v4 as uuidv4 } from 'uuid';
import Shipment from "../Shipment";

function getCaseColour(load) {
  let cases = getTotalCases(load);
  if (cases >= 650) {
    return "red"
  } else if (cases < 650 && cases > 600) {
    return "yellow"
  } else {
    return "gray"
  }
}

function isOverSize(load) {
  return getTotalCases(load) > 600;
}


function getTotalCases(load) {
  const data = load.data || {};
  const shipments = data.shipments || [];
  return shipments.map(shipment => shipment.cases || 0).reduce((a,b) => parseInt(a)+parseInt(b), 0)
}

function defaultShipment(count) {
  
  return {
    drop_order: count,
    dropOrder: count,
    shipment_id: uuidv4(),
    shipper: '',
    customer: '',
    consignee: '',
    cases: 0,
    est_weight: 0,
    paps: ''
  }
}

export default function ShipmentDetails({ load, updateLoad }) {

  const remove = deleted => {
    var formData = {
      ...load.data
    };

    formData.shipments = formData.shipments.filter(shipment => shipment.shipment_id !== deleted.shipment_id);
    updateLoad(formData);

  }

  const addShipment = () => {

    var formData = {
      ...load.data
    };
    let count = formData.shipments.length || 0;
    var newShipment = defaultShipment(count)
    formData.shipments.push(newShipment)

    updateLoad(formData);

  }


  const handleShipmentChange = (event, prop, obj) => {
    var newShipment = {
      ...obj
    }
    newShipment[prop] = event.target.value;
    
    var formData = {
      ...load.data
    };
    formData.shipments[newShipment.drop_order] = newShipment;
    
    updateLoad(formData);

  }

  return (
    <>
      <div className="mt-12">
        <h4 className="text-xl font-bold">Shipments</h4>
        <div className="mt-2 flex items-center text-xs font-semibold ">
          <p className="text-gray-500" >Max cases per truck: 600</p>
          <p className={`ml-4 text-${getCaseColour(load)}-500`}>Total cases: {getTotalCases(load)}</p>
        </div>
        {
          isOverSize(load) ? <p className="mt-2 text-xs text-gray-500 font-normal" >Check with driver for 600 - 650 cases. Anymore than 650 must be split into separate loads.</p> : null
        }
      </div>
      <ul role="list" className="">
        {
          load.data.shipments.map((shipment, idx) => {
            return (
              <Shipment key={idx} idx={idx} shipment={shipment} handleShipmentChange={handleShipmentChange} remove={remove} />
            )
          })
        }
      </ul>
      <button
          type="button"
          onClick={() => addShipment()}
          className="mt-8 bg-gray-100 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Add Shipment
        </button>
    </>
  )
}