import Input from '../../components/Input'

export default function Shipment({ idx, shipment, handleShipmentChange, remove }) {
  return (
    <div className='mt-8'>
      <div className="mb-4 flex items-center ">
        <h4 className='font-semibold text-lg'>Shipment {idx + 1}</h4>
        <button
          type="button"
          onClick={() => remove(shipment)}
          className="ml-4 text-xs font-medium text-gray-500 hover:text-red-500 "
        >
          Remove
        </button>
      </div>
      
      <div key={idx} className=" grid grid-cols-12 gap-2">
        <Input value={shipment.consignee || ''} span={6} onChange={handleShipmentChange} prop='consignee' obj={shipment} name={'Customer'} id={`customer-${idx}`} />
        <Input value={shipment.shipper || ''} span={6} onChange={handleShipmentChange} prop='shipper' obj={shipment} name={'Shipper'} id={`shipper-${idx}`} />
        <Input value={shipment.order_no || ''} onChange={handleShipmentChange} prop='order_no' obj={shipment} name={'Order No.'} id={`order-no-${idx}`} />
        <Input value={shipment.cases || ''} onChange={handleShipmentChange} prop='cases' obj={shipment} name={'Cases'} id={`cases-${idx}`} type="number" />
        <Input value={shipment.est_weight || ''} onChange={handleShipmentChange} prop='est_weight' obj={shipment} name={'Weight (lbs)'} id={`weight-${idx}`} type="number" />
        <Input value={shipment.paps || ''} onChange={handleShipmentChange} prop='paps' obj={shipment} name={'Paps'} id={`paps-${idx}`} />
      </div>
    </div>
  )
}