import SelectMenu from '../../components/SelectMenu';

export function SelectItem({ title, options, handleSelect, prop, value }) {
  return (
    <div className="col-span-12 sm:col-span-6">
      <SelectMenu title={title} options={options || []} onChange={handleSelect} prop={prop} value={value} />
    </div>
  )
}

export function TextItem({ title, type, prop, val, didChange }) {
  return (
    <div className="col-span-12 sm:col-span-6">
      <label htmlFor="temp" className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <input
        type={type || "text"}
        name={prop}
        id={prop}
        value={val || ''}
        onChange={e => didChange(e, prop)}
        autoComplete={prop}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  )
}

export function TextArea({ title, type, prop, val, didChange }) {
  return (
    <div className="col-span-12 sm:col-span-12">
      <label htmlFor={title} className="mb-2 block text-sm font-medium text-gray-700">
        {title}
      </label>
      <div >
        <textarea
          rows={4}
          name={title}
          id={title}
          value={val}
          onChange={e => didChange(e, prop)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  )
}
