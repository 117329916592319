
const isValid = shipment => {
  return shipment.hasOwnProperty("paps") && shipment["paps"] !== "" && shipment["paps"] !== undefined && shipment["paps"] !== null
}

export const getPaps = loads => {
  var paps = {
    "count": 0
  }
  loads.forEach(load => {
    const shipments = load.data.shipments || [];
    shipments.forEach(shipment => {
      if (isValid(shipment)) {
        paps[shipment["paps"]] = true;
        paps["count"] += 1;
      }
    })
  })
  return paps
}

export const generatePap = (key, date, paps, count) => {
  
  const newCount = count + 1
  var pap = `${key}${date}AQ${newCount}`;
  
  if (paps.hasOwnProperty(pap) === false) {
    return {
      paps: pap,
      count: newCount
    };
  }
  
  return this.generatePap(key, date, paps, newCount);
}

export const isPapsValid = loads => {
  var counter = 0;
  var paps = [];

  loads.forEach((load, loadIdx) => {
    const shipments = load.data.shipments || [];
    shipments.forEach((shipment, shipmentIdx) => {
      counter += 1;
      if (isValid(shipment)) {
        paps.push(shipment["paps"])
      }
    })
  })
  return paps.length > 0 && counter === paps.length;
}

export const getDuplicatePaps = loads => {
  var paps = {};

  loads.forEach((load, loadIdx) => {
    const shipments = load.data.shipments || [];
    shipments.forEach((shipment, shipmentIdx) => {
      if (isValid(shipment)) {
        const parent = `Load ${loadIdx + 1} Shipment ${shipmentIdx + 1}`
        if (paps[shipment["paps"]]) {
          paps[shipment["paps"]].push(parent)
        } else {
          paps[shipment["paps"]] = [parent]
        }
      }
    })
  })
  return paps
}