export default function Input({ name, id, value, onChange, prop, obj, type, span }) {
  return (
    <div className={`col-span-${span || 3} sm:col-span-${span || 3}`}>
      <label htmlFor="company" className="block text-sm font-medium text-gray-700">
        {name}
      </label>
      <input
        type={type || "text"}
        name={name}
        id={id}
        value={value}
        onChange={e => onChange(e, prop, obj)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
      />
    </div>
  )
}