import { CalendarIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/react/solid'

function SelectMenu({ options, onChange, shipment }) {
  return (
    <div>
      <select
        id={shipment.shipment_id}
        name={'Drop Order'}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        defaultValue={shipment.drop_order}
        onChange={e => onChange(e, shipment)}
      >
        {
          options.map((item, idx) => <option key={idx} value={item.value} >{item.name}</option>)
        }
      </select>
    </div>
  )
}

export default function DropOrderList({ shipments, onChange }) {
  const data = shipments || [];
  const options = [{ value: '', name: 'Select Drop Order' }].concat(shipments.map((ship, idx) => {
    return {
      value: `${idx}`,
      name: `Drop ${idx + 1}`
    }
  }))
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data.map((shipment, index) => (
          <li key={index}>
            <a className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-blue-600 truncate">{shipment.consignee || ''}</p>
                  
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {`${shipment.cases || 0} Cases`}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <SelectMenu options={options} shipment={shipment} onChange={onChange}  />
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
