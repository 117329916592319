import { useState } from "react";
import { SelectItem, TextItem } from "../Input";

function getSelect(load, prop) {
  if (load.data && load.data[prop] && load.data[prop].value) {
    return load.data[prop].value;
  }
  return ''
}

export default function LoadDetails({ load, drivers, trucks, trailers, handleTextChange, updateLoad }) {

  const [options, setOptions] = useState({  drivers, trucks, trailers });

  const driver = getSelect(load, 'driver'); 
  const truck = getSelect(load, 'truck');
  const trailer = getSelect(load, 'trailer');

  const handleOptionChange = (event) => {
    
    const prop = event.target.name;
    const selected = options[`${prop}s`].filter(item => item.entity_id === event.target.value);
    const obj = selected && selected.length && selected[0];

    if (obj) {

      var updatedForm = {
        ...load.data
      }
  
      updatedForm[prop] = obj;
      
      updateLoad(updatedForm)

    }

  }

  return (
    <div className="mt-6 grid grid-cols-12 gap-6">
      <SelectItem title={'Driver'} options={drivers} handleSelect={handleOptionChange} prop='driver' value={driver} />
      <SelectItem title={'Truck'} options={trucks} handleSelect={handleOptionChange} prop='truck' value={truck} />
      <SelectItem title={'Trailer'} options={trailers} handleSelect={handleOptionChange} prop='trailer' value={trailer} />
      <TextItem title="Temp (F)" prop="temp" val={load.data.temp} didChange={handleTextChange} />
      <TextItem title="Ready Date" type="date" prop="date" val={load.data.date} didChange={handleTextChange} />
      <TextItem title="Pick Up Time (24hr)" prop="time" val={load.data.time} didChange={handleTextChange} />
    </div>
  )
}