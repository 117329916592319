import axios from "axios"


export const getLoad = async (loadId, token) => {
  // const token = localStorage.getItem('token');
  const baseUrl = process.env.REACT_APP_AQT_BASE_URL;

  var config = {
    method: 'get',
    url: `${baseUrl}/load/form/${loadId}?token=${token}`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };
  
  const response = await axios(config);
  return response.data.data;

}




export const getDrivers = async (loadId, token) => {
  
  const baseUrl = process.env.REACT_APP_AQT_BASE_URL;

  var config = {
    method: 'get',
    url: `${baseUrl}/driver/form/${loadId}?token=${token}`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };
  
  const response = await axios(config);
  return response.data.data;

}


export const getFleet = async (loadId, token) => {

  const baseUrl = process.env.REACT_APP_AQT_BASE_URL;
  var config = {
    method: 'get',
    url: `${baseUrl}/fleet/form/${loadId}?token=${token}`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };
  
  const response = await axios(config);
  return response.data.data;

}







/**
 * Save Load
 * @param {String} loadId loadId passed in url query params
 * @param {String} token token passed in url query params
 * @param {Object} data form data including entity_id
 * @returns 
 */
export const saveForm = async (loadId, token, data) => {
  const baseUrl = process.env.REACT_APP_AQT_BASE_URL;
  var config = {
    method: 'put',
    url: `${baseUrl}/load/form/${loadId}?token=${token}`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify(data)
  };
  
  const response = await axios(config);
  return response;
}



export const getDropOrder = async (formId, loadId, token) => {
  
  const baseUrl = process.env.REACT_APP_AQT_BASE_URL;

  var config = {
    method: 'get',
    url: `${baseUrl}/load/drop-order/${formId}?loadId=${loadId}&token=${token}`,
    headers: { 
      'Authorization': `Bearer ${token}`
    }
  };
  
  const response = await axios(config);
  return response.data.data;

}


/**
 * Save Drop Order
 * @param {String} loadId loadId passed in url query params
 * @param {String} token token passed in url query params
 * @param {Object} data form data including entity_id
 * @returns 
 */
 export const saveDropOrder = async (formId, loadId, token, data) => {
  const baseUrl = process.env.REACT_APP_AQT_BASE_URL;
  var config = {
    method: 'put',
    url: `${baseUrl}/load/drop-order/${formId}?loadId=${loadId}&token=${token}`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify(data)
  };
  
  const response = await axios(config);
  return response;
}