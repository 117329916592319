
import { TextArea } from "../Input";
import LoadDetails from "./LoadDetails";
import ShipmentDetails from "./ShipmentDetails";


export default function Load({ idx, load, drivers, trucks, trailers, deleteTrip, updateLoad }) {

  
  const updateParent = formData => {
    updateLoad({
      ...load,
      data: formData
    });
  }


  const handleTextChange = (event, prop) => {
    
    const updatedForm = {
      ...load.data
    };
    updatedForm[prop] = event.target.value;
    updateParent(updatedForm);

  }

  return (
    <div className="py-6 px-4 sm:p-6 lg:pb-8">
      <div className="flex items-center ">
        <h3 className="font-extrabold text-2xl">Load {idx + 1}</h3>
        <button
          type="button"
          onClick={() => deleteTrip(load)}
          className="ml-4 bg-white rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Delete
        </button>
      </div>
      
      <LoadDetails 
        load={load}
        drivers={drivers}
        trucks={trucks}
        trailers={trailers}
        updateLoad={updateParent}
        handleTextChange={handleTextChange}
        />
      
      <ShipmentDetails load={load} updateLoad={updateParent} />
      
      <div className="my-8">
        <TextArea title="Backhaul Driver Notes" prop="backhaul_notes" val={load.data.backhaul_notes} didChange={handleTextChange} />
      </div>
      
    </div>
  )
}
