import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import Home from './containers/home/Home';
import Southbound from './containers/southbound/Southbound';
import SouthboundDriver from './containers/southboundDriver/SoutboundDriver';





export default function App() {

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  
  
  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();      
      localStorage.setItem('token', token);

    } catch (error) {
    }
  };

  callApi();

  let routes = (
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/Gy9Gnzw1GLY6KHRA51Rq5k" component={Southbound} />
      <Route path="/b4fdf6272b1b" component={SouthboundDriver} />
    </Switch>
  )


  return (
      <Router >
        <div className=" bg-gray-100"> 
          {routes}
        </div>
      </Router>
    );

}

