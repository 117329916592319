import { CheckIcon } from '@heroicons/react/outline'

export default function SuccessModal() {

  return (
    <div>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3> Drop Order Successful</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            You can now close this form
          </p>
        </div>
      </div>
    </div>
  )
}
