/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function SelectMenu({ title, options, onChange, prop, value }) {
  return (
    <div>
      <label htmlFor={prop} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <select
        id={prop}
        name={prop}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        value={value || ''}
        onChange={e => onChange(e)}
      >
        {
          options.map((item, idx) => <option key={idx} value={item.value} >{item.name}</option>)
        }
      </select>
    </div>
  )
}
